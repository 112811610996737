function toggleStudyList(content) {
    content.querySelectorAll("[dataset-study-form]").forEach(form => {
        var $studyCheckList = $("#study-checklist"),
            $studySelectList = $("#study-selectlist");
        function toggle() {
            var isSingleStudy = $(form).find("input[name=Type]:checked").val() == "SingleStudy";
            if (isSingleStudy) {
                $studySelectList.addClass("visible")
                $studyCheckList.removeClass("visible")
            } else {
                $studySelectList.removeClass("visible")
                $studyCheckList.addClass("visible")
            }
        }

        toggle();
        $(form).find('input[name=Type]').on("change", toggle);
    });
};

function toggleRegistryInput(content) {
    content.querySelectorAll("[dataset-study-form]").forEach(form => {
        var $registryInput = $("#registry-input");
        function toggle() {
            var isRegistry = $(form).find("input[name=Type]:checked").val() == "Registry";

            if (isRegistry) {
                $registryInput.removeClass("hidden")
            } else {
                $registryInput.addClass("hidden")
            }
        }

        toggle();
        $(form).find('input[name=Type]').on("change", toggle);
    });
};

function toggleFilterCriteria(content) {
    content.querySelectorAll("[dataset-filter-criteria]").forEach(form => {
        function toggleSubject() {
            var $custom = $("#subject-custom"),
                $noncustom = $("#subject-noncustom");

            var customInput = $(form).find('#sub-has-custom input[type=radio]:checked').val();
            console.log("sub", customInput);

            if (customInput == "True") {
                $custom.removeClass("hidden");
                $noncustom.addClass("hidden");
            }
            else {
                $custom.addClass("hidden");
                $noncustom.removeClass("hidden");
            }
        }

        function toggleStudyPartner() {
            var $custom = $("#sp-custom"),
                $noncustom = $("#sp-noncustom");

            var customInput = $(form).find('#sp-has-custom input[type=radio]:checked').val();
            console.log("sp", customInput);
            if (customInput == "True") {
                $custom.removeClass("hidden");
                $noncustom.addClass("hidden");
            }
            else {
                $custom.addClass("hidden");
                $noncustom.removeClass("hidden");
            }
        }

        toggleSubject();
        toggleStudyPartner();
        $(form).find('#sub-has-custom').on("change", toggleSubject);
        $(form).find('#sp-has-custom').on("change", toggleStudyPartner);
    });
}

window.addEventListener("htmx:load", e => toggleStudyList(e.target));
window.addEventListener("htmx:load", e => toggleRegistryInput(e.target));
window.addEventListener("htmx:load", e => toggleFilterCriteria(e.target));
