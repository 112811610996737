import "datatables.net-bs";
import { localizeDateTimeElements } from "./datetime-localize";
import { initializeMarkdownEditors } from "./markdown";

function loadDataTables(content) {
    let tables = content.querySelectorAll(".table-datatable");
    tables.forEach(table => {
        if ($.fn.dataTable.isDataTable(table)) {
        }
        else {
            let dt = $(table).DataTable({ orderMulti: true });

            // if we are inside a form, we need to clear filters, so all rows are submitted
            // should also not use paging
            let form = table.closest("form");
            if (form) {
                // regular submits, but not in time for hx-post for some reason
                form.addEventListener('submit', function (e) {
                    //console.log("submit")
                    dt.search('').columns().search('').draw();
                });

                let clearing = false;

                dt.on('draw', function () {
                    //console.log("all clear!")
                    clearing = false;
                });

                // in time for an hx-post
                form.addEventListener('htmx:confirm', function (evt) {
                    //console.log("htmx:confirm")
                    evt.preventDefault();
                    clearing = true;
                    dt.search('').columns().search('').draw();
                    while (clearing) {
                        //console.log("htmx is waiting...")
                        sleep(100);
                    }
                    evt.detail.issueRequest();
                });
            }

            dt.on("draw.dt", function (e) {
                localizeDateTimeElements(e.target);
                initializeMarkdownEditors(e.target);
            });
        }
    });

    content.querySelectorAll('a[data-toggle="tab"]').forEach(tab => {
        $(tab).on('shown.bs.tab', function (e) {
            $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
        });
    });
}

window.addEventListener("htmx:load", e => loadDataTables(e.target));
