function renderEnrollmentChart(element, series, categories) {
    import("apexcharts").then(({ default: ApexCharts }) => {
        let options = {
            series,
            xaxis: {
                categories,
                labels: {
                    trim: true
                },
                maxHeight: 20
            },
            colors: ["#5fa2dd", "#9cc96b", "#ebaa4b", "#f76c51"],
            chart: {
                type: "bar",
                height: 400
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded"
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"]
            },
            yaxis: {
                title: {
                    text: "# of Subjects"
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: (val) => (`${val} Subject${val != 1 ? "s" : ""}`)
                }
            }
        };

        var chart = new ApexCharts(element, options);
        chart.render();
        return chart;
    });
}

const procedureStatusColors = {
    "Pending": "#e3e8ee",
    "Collected": "#9cc96b",
    "Maybe Collected": "#50c7a7",
    "Started": "#4ebcda",
    "Scheduled": "#5fa2dd",
    "Not in Protocol": "#3b4146",
    "Technical Difficulties": "#f76c51",
    "Unable to Perform": "#986291",
    "Refused": "#986291",
    "Cancelled": "#3b4146",
}

function renderEcdrChart(element, series, categories) {

    let colors = categories.map((x) => procedureStatusColors[x])

    import("apexcharts").then(({ default: ApexCharts }) => {
        let options = {
            series,
            labels: categories,
            chart: {
                type: "donut",
                height: 400,
            },
            colors: colors,
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                color: '#515151'
                            }
                        }
                    }
                }
            }
        };

        var chart = new ApexCharts(element, options);
        chart.render();
        return chart;
    });
}

window.renderEnrollmentChart = renderEnrollmentChart;
window.renderEcdrChart = renderEcdrChart;